import React, { useState, useEffect } from 'react';
import './Footer.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Container } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { ReactComponent as TwitterIcon } from './assets/twittericon.svg';
import { ReactComponent as LinkedInIcon } from './assets/linkedinicon.svg';
import { ReactComponent as FbIcon } from './assets/fbicon.svg';
import { ReactComponent as InstaIcon } from './assets/instaicon.svg';
import Subscribe from "./Subscribe"
import apiService from './services/apiservice';
import { ReactComponent as SuccessIcon } from './assets/successicon.svg';

export default function Footer() {
    const [contactusemail, setContactUsEmail] = useState('');
    const [firstname, setFirstName] = useState('');
    const [lastname, setLastName] = useState('');
    const [message, setMessage] = useState('');
    const [contactloading, setContactLoading] = useState(false);
    const [showcontactsuccess, setShowContactSuccess] = useState(false);

    const baseURL = process.env.REACT_APP_API_URL || "http://18.205.155.104:8000";
    const handleFirstNameChange = (e: any) => {
        setFirstName(e.target.value);
    };
    const handleLastNameChange = (e: any) => {
        setLastName(e.target.value);
    };
    const handleContatusEmailChange = (e: any) => {
        setContactUsEmail(e.target.value);
    };
    const handleMessageChange = (e: any) => {
        setMessage(e.target.value);
    };
    
    const handleContactSubmit = async(e: any) => {
        e.preventDefault();
        setContactLoading(true);
        let PostData = {
            first_name: firstname,
            last_name: lastname,
            email: contactusemail,
            message: message,
        }
        try {
            const response = await apiService.post('/api/contact_us', PostData);
            if (response) {
                setContactLoading(false);
                setFirstName("");
                setLastName("");
                setContactUsEmail("");
                setMessage("");
                setShowContactSuccess(true);
                // Hide the success message after 3 seconds (adjust as needed)
                setTimeout(() => {
                    setShowContactSuccess(false);
                }, 3000);
            }
        } catch (error: any) {
            console.error('Error fetching data:', error.message);
            setContactLoading(false);
        }
    };
    return (
        <div className="">
            <Subscribe />
            <div className="footer-section">
                <Container>
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={5} xl={5}>
                            { showcontactsuccess &&
                                <div className="contact-success-message">
                                    <SuccessIcon style={{width:"20px", height:"20px", marginRight:"8px", marginBottom:"1px"}}/>
                                        Contact Us Form Submitted Successfully.
                                </div>
                            }
                            <div className="contact-title">
                                Contact Us
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Container>
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={5} xl={5}>
                            <Form onSubmit={handleContactSubmit}>
                                <Form.Group as={Row} className="mb-3 mt-4" controlId="contactusForm.ControlInput1">
                                    <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <Form.Label>First Name</Form.Label>
                                        <Form.Control type="text" placeholder="First Name" value= {firstname} onChange={handleFirstNameChange}/>
                                    </Col>
                                    <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <Form.Label>Last Name</Form.Label>
                                        <Form.Control type="text" placeholder="Last Name" value= {lastname} onChange={handleLastNameChange}/>
                                    </Col>
                                </Form.Group>
                                <Form.Group className="mb-3 mt-3" controlId="contactusForm.ControlInput2">
                                    <Form.Label>Email Address</Form.Label>
                                    <Form.Control type="email" placeholder="Enter Email Address" value= {contactusemail} onChange={handleContatusEmailChange} />
                                    <Form.Label className="mt-1 supplemental-text">Example: johnsmith@gmail.com</Form.Label>
                                </Form.Group>
                                <Form.Group className="mb-4" controlId="contactusForm.ControlTextarea1">
                                    <Form.Label>Your Message</Form.Label>
                                    <Form.Control as="textarea" rows={3} value= {message} onChange={handleMessageChange}/>
                                </Form.Group>
                                {!contactloading && 
                                    <button className="submit-btn" type="submit">Send Message</button>
                                }
                                { contactloading && 
                                    <button className="submit-btn-loading" type="submit">Loading...</button>
                                }
                            </Form>
                        </Col>
                        <Col xs={12} sm={12} md={5} lg={4} xl={4} className="col-align">
                            <div className="mt-4 mb-4">
                                <a href="/" className="footer-list">Home</a>
                            </div>
                            <div className="mt-4 mb-4">
                                <a href="/about" className="footer-list">About Us</a>
                            </div>
                            <div className="mt-4 mb-4">
                                <a href="/privacy-policy" target='_blank'
                                className="footer-list">Privacy Policy</a>
                            </div>
                            <div className="mt-5 mb-4 follow-us">
                                Follow us
                            </div>
                            <div className="mt-3 mb-4">
                                <a href="https://x.com/opie_utscience" target="_blank">
                                    <TwitterIcon className="icon-section"/>
                                </a>
                                <a href="https://www.linkedin.com/company/understand-the-science" target="_blank">
                                    <LinkedInIcon className="icon-section"/>
                                </a>
                                <a href="https://www.facebook.com/UnderstandtheScience" target="_blank">
                                    <FbIcon className="icon-section"/>
                                </a>
                                <a href="https://www.instagram.com/understandthescience/" target="_blank">
                                    <InstaIcon className="icon-section"/>
                                </a>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Container>
                    <Row className="mb-3 mt-4 copyright-content">
                        <Col>
                            We are a 501c3 non-profit comprised of experts committed to using our pharmaceutical education, training, 
                            and technical experience to simplify science. Our web platform features visual learning videos ({`<2 minutes in duration`}) 
                            presented by our scientists with an artificial intelligence (AI) assistant that answers questions instantly and in laymen’s terms. 
                            The reference-supported medical information provides a trusted source for medical education accessible to anyone.
                            With Watson is a trademark of International Business Machines Corporation, registered in many jurisdictions worldwide.

                        </Col>
                    </Row>
                    <Row className="mb-3 mt-4 copyright-content">
                        <Col>
                            This website is a registered trademark of Understand the Science. 
                            No part of this site or any of its content, understandthescience.org, 
                            may be reproduced in whole or in part in any manner without the permission of Understand the Science.
                        </Col>
                    </Row>
                    <Row className="mb-3 mt-4 copyright-content">
                        <Col>
                            This website does not provide medical advice. 
                            It is intended for educational purposes only. 
                            It is not a substitute for professional medical advice, diagnosis or treatment.
                        </Col>
                    </Row>
                </Container>
                <Container>
                    <Row className="mt-4 copyright-content">
                        <Col>
                            © 2024 Understand the Science
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
}

import React, { useState, useEffect } from 'react';
import { Row, Col, Breadcrumb, Container } from 'react-bootstrap';
import apiService from './services/apiservice';
export default function Comingsoon() {
    const baseURL = process.env.REACT_APP_API_URL || "http://18.205.155.104:8000";
    useEffect(() => {
    }, [])
    return (
        <div className="coming-soon-section pt-3">
            <Container>
                <Row>
                    <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                        <div className="newrelease-section">
                            <img src={require('./assets/new-release.png')}
                                style={{ width: "100%", height: "auto" }} alt="newrelease" />
                        </div>
                        <div className="newrelease-content">
                            <div className="newrelease-title">Coming Soon</div>
                            <div className="newrelease-desc">Prostate Cancer Staging</div>
                            <div className="newrelease-duration" style={{visibility:"hidden"}}>1m 01s</div>
                        </div>

                    </Col>
                    <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                        <div className="comingsoon-section">
                            <img src={require('./assets/coming-soon.png')} className="img-fluid" alt="comingsoon" />
                            <div className="comingsoon-title">Coming Soon to UTS</div>
                            <div className="comingsoon-desc">Cardiovascular Disease</div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
import React from 'react';
import './Sponser.css';
import { Container , Row, Col } from 'react-bootstrap';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
export default function Sponser() {

    const carouselresponsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 4
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 4
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };

    return(
        <div className="pt-5">
            <Container>
                {/* <div className="sponser-title">
                        Explore Our Sponsors
                </div> */}
                <Carousel responsive={carouselresponsive} className="pt-5">
                    <div style={{width:"280px", position:"relative" , top:"10px", left:"36px"}}>
                        <img src={require('./assets/AdMedlogo.png')}  className="img-fluid" alt="AdMed" />
                    </div>
                    <div style={{width:"280px", position:"relative" , bottom:"48px", left:"36px"}}>
                        <img src={require('./assets/Watsonlogo.png')}  className="img-fluid" alt="Watson" />
                    </div>
                    <div style={{width:"280px", position:"relative" , top:"20px", left:"30px"}}>
                        <img src={require('./assets/WongDoody_wordmark_RGB_DarkBlue.png')}
                        className="img-fluid" alt="Watson" />
                    </div>
                    {/* <div style={{width:"260px",}}>
                        <img src={require('./assets/sloan.png')}  className="img-fluid" alt="sloan" />
                    </div>
                    <div style={{width:"260px"}}>
                        <img src={require('./assets/columbia.png')}  className="img-fluid" alt="columbia" />
                    </div>
                    <div style={{width:"260px"}}>
                        <img src={require('./assets/johns hopkins.png')}  className="img-fluid" alt="johns hopkins" />
                    </div>
                    <div style={{width:"260px", position:"relative" , bottom:"24px"}}>
                        <img src={require('./assets/nyu.png')}   className="img-fluid" alt="nyu" />
                    </div>
                    <div style={{width:"260px"}}>
                        <img src={require('./assets/sloan.png')}  className="img-fluid" alt="sloan" />
                    </div>
                    <div style={{width:"260px"}}>
                        <img src={require('./assets/columbia.png')}  className="img-fluid" alt="columbia" />
                    </div>
                    <div style={{width:"260px"}}>
                        <img src={require('./assets/johns hopkins.png')}  className="img-fluid" alt="johns hopkins" />
                    </div>
                    <div style={{width:"260px", position:"relative" , bottom:"24px"}}>
                        <img src={require('./assets/nyu.png')}   className="img-fluid" alt="nyu" />
                    </div> */}
                    {/* <div>
                        <img src={require('./assets/brigham.png')}  className="img-fluid" alt="brigham" />
                    </div>
                    <div>
                        <img src={require('./assets/emory.png')}  className="img-fluid" alt="emory" />
                    </div>
                    <div>
                        <img src={require('./assets/swmc.png')}  className="img-fluid" alt="swmc" />
                    </div> */}
                </Carousel> 
            </Container>
        </div>
    )
}
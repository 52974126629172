import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Spinner } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import "./Subscribe.css"
import apiService from './services/apiservice';
import { ReactComponent as SuccessIcon } from './assets/successicon.svg';

export default function Header() {
    const [subscribeemail, setSubscribeEmail] = useState('');
    const [isSubloading, setisSubLoading] = useState(false);
    const [showSubSuccess, setShowSubSuccess] = useState(false);


    const baseURL = process.env.REACT_APP_API_URL || "http://18.205.155.104:8000";

    const handleSubscribeEmailChange = (e: any) => {
        setSubscribeEmail(e.target.value);
    };

    const handleSubscribeSubmit = async (e: any) => {
        e.preventDefault();
        setisSubLoading(true);
        let PostData = {
            email: subscribeemail,
        }
        try {
            const response = await apiService.post('/api/subscriber', PostData);
            if (response) {
                setisSubLoading(false);
                setSubscribeEmail("");
                setShowSubSuccess(true);
                // Hide the success message after 3 seconds (adjust as needed)
                setTimeout(() => {
                    setShowSubSuccess(false);
                }, 3000);
            }
        } catch (error: any) {
            console.error('Error fetching data:', error.message);
            setisSubLoading(false);
        }
    };
    return (  

        <div className="subscribe-section pt-5 pb-5" style={{position:"relative"}}>
            <div className="ribbon">COMING SOON</div>

            <Container>

                <Row className="subscribe-row">
               
                    <Col xs={12} sm={12} md={12} lg={5} xl={5}>
                        
                {/* <ComingSoonIcon /> */}
                        <div className="subscribe-header">
                            Subscribe to Our Newsletter
                        </div>
                        <div className="subscribe-content mt-3">
                            Sending you specialized content on health and wellness from our field-leading experts.
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={6} xl={6} style={{marginLeft:"auto"}}>
                    <div className="ribbon">COMING SOON</div>
                    {/* <div className="subscribe-header">
                            Subscribe to Our Newsletter
                        </div>
                        <div className="subscribe-content mt-3">
                            Sending you specialized content on health and wellness from our field leading experts.
                        </div> */}
                        { showSubSuccess &&
                            <Col className="success-message">
                                <SuccessIcon style={{width:"20px", height:"20px", marginRight:"8px", marginBottom:"1px"}}/>
                                    Subscribed Successfully.
                            </Col>
                        }
                        <Form onSubmit={handleSubscribeSubmit}>
                            <Form.Group as={Row} className="mb-3 mt-4" controlId="contactusForm.ControlInput1">
                                <Col xs={12} sm={12} md={7} lg={8} xl={8}>
                                    <Form.Control type="email" placeholder="Enter email address"   value= {subscribeemail} onChange={handleSubscribeEmailChange}/>
                                    <Form.Label className="mt-1" style={{fontSize:"14px", color:"#000"}}>Example: johnsmith@gmail.com</Form.Label>
                                </Col>
                                <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                    {!isSubloading &&  
                                        <button className="subscribe-btn" type="submit">
                                            Subscribe
                                        </button>
                                    }     
                                    { isSubloading && 
                                        <button className="subscribe-btn-loading"> 
                                            Loading...
                                        </button>
                                    }
                                </Col>
                            </Form.Group>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
import React, {useState} from 'react';
import './Header.css';
import {Container, Row, Col, DropdownDivider, InputGroup} from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { ReactComponent as Logo } from './assets/logo.svg';
import { ReactComponent as Arrowdown } from './assets/arrowdown.svg';
import { ReactComponent as SearchIcon } from './assets/searchicon.svg';
import {Menus} from "./utils/constants"
import Form from 'react-bootstrap/Form';
import { ReactComponent as TwitterIcon } from './assets/twittericon-white.svg';
import { ReactComponent as LinkedInIcon } from './assets/linkediconwhite.svg';
import { ReactComponent as FbIcon } from './assets/fbiconwhite.svg';
import { ReactComponent as InstaIcon } from './assets/instaiconwhite.svg';

export default function Header() {
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearchChange = (e: any) => {
    setSearchTerm(e.target.value);
  };
  
  const filteredItems = Menus.map(menu => ({
    menutitle: menu.menutitle,
    menuitem: menu.menuitem.filter(item =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    ),
  })).filter(menu => menu.menuitem.length > 0);



  return (
    <div className="header-section">
      <Navbar collapseOnSelect expand="lg" className="nav-background" fixed="top">
        <Container>
          <Navbar.Brand href="/"><Logo /></Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto">
              <NavDropdown className="full-width mainmenu-title" style={{ top: "6px", position: "relative" }} title={
                <span>Understand <Arrowdown style={{ marginLeft: "12px" }} /></span>
              }>
                <Container style={{margin:"1em 0 1em 0"}}>
                  <Row className="mb-3 ms-1">
                    <Col xs={12} sm={10} md={9} lg={8} xl={5}>
                      <Form>
                        <Form.Group as={Col}>
                          <InputGroup>
                            <InputGroup.Text>
                              <SearchIcon />
                            </InputGroup.Text>
                            <Form.Control
                              type="text"
                              placeholder="Search..."
                              onChange={handleSearchChange}
                              value={searchTerm}
                              className="menu-search"
                            />
                          </InputGroup>
                        </Form.Group>
                      </Form>
                    </Col>
                  </Row>
                  <Row>
                  <Col xs={12} sm={6} md={6} lg={3} xl={3}>
                    {filteredItems.filter((data: any) => data.menutitle == "Autoimmune & Inflammatory Diseases")
                      .map((menu: any, i: any) => (
                        <span key={i}>
                          <NavDropdown.Item className="menu-title" disabled>
                            {menu.menutitle}
                          </NavDropdown.Item>
                          <NavDropdown.Divider />
                          {menu.menuitem.map((item: any, index: any) => (
                            <NavDropdown.Item href={`/understand/${menu.menutitle}${item.url}`} className="menu-item pt-2" 
                              key={index} disabled={item.disable}>
                              {item.name}
                            </NavDropdown.Item>
                          ))}
                        </span>
                      ))}
                      {filteredItems.filter((data: any) => data.menutitle == "Cancer")
                      .map((menu: any, i: any) => (
                        <span key={i}>
                          <NavDropdown.Item className="menu-title" disabled>
                            {menu.menutitle}
                          </NavDropdown.Item>
                          <NavDropdown.Divider />
                          {menu.menuitem.slice(0,5).map((item: any, index: any) => (
                            <NavDropdown.Item href={`/understand/${menu.menutitle}${item.url}`} className="menu-item pt-2" 
                              key={index} disabled={item.disable}>
                              {item.name}
                            </NavDropdown.Item>
                          ))}
                        </span>
                      ))}
                  </Col>
                  <Col xs={12} sm={6} md={6} lg={3} xl={3}>
                    {filteredItems.filter((data: any) => data.menutitle == "Cancer")
                      .map((menu: any, i: any) => (
                          <span key={i}>
                            {menu.menuitem.slice(6,15).map((item: any, index: any) => (
                              <NavDropdown.Item href={`/understand/${menu.menutitle}${item.url}`} className="menu-item pt-2" 
                                key={index} disabled={item.disable}>
                                {item.name}
                              </NavDropdown.Item>
                            ))}
                        </span>
                      ))}
                      {filteredItems.filter((data: any) => data.menutitle == "Cardiovascular Diseases")
                      .map((menu: any, i: any) => (
                        <span key={i}>
                          <NavDropdown.Item className="menu-title" disabled>
                            {menu.menutitle}
                          </NavDropdown.Item>
                          <NavDropdown.Divider />
                          {menu.menuitem.map((item: any, index: any) => (
                            <NavDropdown.Item href={`/understand/${menu.menutitle}${item.url}`} className="menu-item pt-2" 
                              key={index} disabled={item.disable}>
                              {item.name}
                            </NavDropdown.Item>
                          ))}
                        </span>
                      ))}
                  </Col>
                  <Col xs={12} sm={6} md={6} lg={3} xl={3}>
                    {filteredItems.filter((data: any) => data.menutitle == "Geriatric Health")
                      .map((menu: any, i: any) => (
                        <span key={i}>
                          <NavDropdown.Item className="menu-title" disabled>
                            {menu.menutitle}
                          </NavDropdown.Item>
                          <NavDropdown.Divider />
                          {menu.menuitem.map((item: any, index: any) => (
                            <NavDropdown.Item href={`/understand/${menu.menutitle}${item.url}`} className="menu-item pt-2" 
                              key={index} disabled={item.disable}>
                              {item.name}
                            </NavDropdown.Item>
                          ))}
                        </span>
                      ))} 
                      {filteredItems.filter((data: any) => data.menutitle == "Neurological Diseases & Conditions")
                        .map((menu: any, i: any) => (
                          <span key={i}>
                            <NavDropdown.Item className="menu-title" disabled>
                              {menu.menutitle}
                            </NavDropdown.Item>
                            <NavDropdown.Divider />
                            {menu.menuitem.map((item: any, index: any) => (
                              <NavDropdown.Item href={`/understand/${menu.menutitle}${item.url}`} className="menu-item pt-2" 
                                key={index} disabled={item.disable}>
                                {item.name}
                              </NavDropdown.Item>
                            ))}
                          </span>
                        ))}
                        {filteredItems.filter((data: any) => data.menutitle == "Urinary & Reproductive Health")
                        .map((menu: any, i: any) => (
                          <span key={i}>
                            <NavDropdown.Item className="menu-title" disabled>
                              {menu.menutitle}
                            </NavDropdown.Item>
                            <NavDropdown.Divider />
                            {menu.menuitem.map((item: any, index: any) => (
                              <NavDropdown.Item href={`/understand/${menu.menutitle}${item.url}`} className="menu-item pt-2" 
                                key={index} disabled={item.disable}>
                                {item.name}
                              </NavDropdown.Item>
                            ))}
                          </span>
                        ))}
                      
                  </Col>
                  <Col xs={12} sm={6} md={6} lg={3} xl={3}>
                    {filteredItems.filter((data: any) => data.menutitle == "Respiratory Conditions")
                      .map((menu: any, i: any) => (
                        <span key={i}>
                          <NavDropdown.Item className="menu-title" disabled>
                            {menu.menutitle}
                          </NavDropdown.Item>
                          <NavDropdown.Divider />
                          {menu.menuitem.map((item: any, index: any) => (
                            <NavDropdown.Item href={`/understand/${menu.menutitle}${item.url}`} className="menu-item pt-2" 
                              key={index} disabled={item.disable}>
                              {item.name}
                            </NavDropdown.Item>
                          ))}
                        </span>
                      ))}
                      {filteredItems.filter((data: any) => data.menutitle == "How Therapies Work")
                      .map((menu: any, i: any) => (
                        <span key={i}>
                          <NavDropdown.Item className="menu-title" disabled>
                            {menu.menutitle}
                          </NavDropdown.Item>
                          <NavDropdown.Divider />
                          {menu.menuitem.map((item: any, index: any) => (
                            <NavDropdown.Item href={`/understand/${menu.menutitle}${item.url}`} className="menu-item pt-2" 
                              key={index} disabled={item.disable}>
                              {item.name}
                            </NavDropdown.Item>
                          ))}
                        </span>
                      ))}
                      {filteredItems.filter((data: any) => data.menutitle == "Public Health Topics")
                      .map((menu: any, i: any) => (
                        <span key={i}>
                          <NavDropdown.Item className="menu-title" disabled>
                            {menu.menutitle}
                          </NavDropdown.Item>
                          <NavDropdown.Divider />
                          {menu.menuitem.map((item: any, index: any) => (
                            <NavDropdown.Item href={`/understand/${menu.menutitle}${item.url}`} className="menu-item pt-2" 
                              key={index} disabled={item.disable}>
                              {item.name}
                            </NavDropdown.Item>
                          ))}
                        </span>
                      ))}
                  </Col>          
                  </Row>
                </Container>
              </NavDropdown>
              <NavDropdown style={{ top: "6px" }} title={
                  <span>Explore <Arrowdown style={{ marginLeft: "12px" }} /> </span>
                }>
                <NavDropdown.Item href={`/explore/See Our Full Library`} className="menu-item pt-2">See Our Full Library</NavDropdown.Item>
                <NavDropdown.Item href={`/explore/See What's Coming Soon`} className="menu-item">See What's Coming Soon</NavDropdown.Item>
              </NavDropdown>
              <Nav.Link href="/about/" style={{ top: "6px", position:"relative" }}>About Us</Nav.Link>
            </Nav>
          </Navbar.Collapse>
          <Navbar.Brand href="https://x.com/opie_utscience" target="_blank">
            <TwitterIcon className='header-socialicon-section'/>
          </Navbar.Brand>
          <Navbar.Brand href="https://www.linkedin.com/company/understand-the-science" target="_blank">
            <LinkedInIcon className='header-socialicon-section'/>
          </Navbar.Brand>
          <Navbar.Brand href="https://www.facebook.com/UnderstandtheScience" target="_blank">
            <FbIcon className='header-socialicon-section'/>
          </Navbar.Brand>
          <Navbar.Brand href="https://www.instagram.com/understandthescience/" target="_blank">
            <InstaIcon className='header-socialicon-section'/>
          </Navbar.Brand>
        </Container>
      </Navbar>
    </div>
  );
}

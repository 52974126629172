
export const Menus = [
    {
        menutitle: 'Autoimmune & Inflammatory Diseases',
        menuitem: [
            { name: 'Lupus', url: "/Lupus", disable:true },
            { name: 'Multiple Sclerosis', url: "/Multiple Sclerosis", disable:true },
            { name: 'Psoriasis', url: "/Psoriasis", disable:true },
            { name: 'Psoriatic Arthritis', url: "/Psoriatic Arthritis", disable:true },
            { name: 'Rheumatoid Arthritis', url: "/Rheumatoid Arthritis", disable:true },
        ],
    },
    {
        menutitle: 'Cancer',
        menuitem: [
            { name: 'Bladder Cancer', url: "/Bladder Cancer", disable:true },
            { name: 'Breast Cancer', url: "/Breast Cancer", disable:false },
            { name: 'Cervical Cancer', url: "/Cervical Cancer", disable:true },
            { name: 'Endometrial Cancer', url: "/Endometrial Cancer", disable:true },
            { name: 'Esophageal/Gastric Cancer', url: "/Esophageal Cancer", disable:true },
            { name: 'Esophageal/Head and Neck Cancer', url: "/Head and Neck Cancer", disable:true },
            { name: 'Liver Cancer', url: "/Liver Cancer", disable:true },
            { name: 'Lung Cancer', url: "/Lung Cancer", disable:true },
            { name: 'Lymphoma', url: "/Lymphoma", disable:true },
            { name: 'Melanoma/Skin Cancer', url: "/Melanoma Cancer", disable:true },
            { name: 'Multiple Myeloma', url: "/Multiple Myeloma", disable:true },
            { name: 'Ovarian Cancer', url: "/Ovarian Cancer", disable:true },
            { name: 'Prostate Cancer', url: "/Prostate Cancer" , disable:true},
            { name: 'Testicular Cancer', url: "/Testicular Cancer" , disable:true},
            { name: 'Kidney Cancer', url: "/Kidney Cancer" , disable:true},
        ],
    },
    {
        menutitle: 'Cardiovascular Diseases',
        menuitem: [
            { name: 'Heart Disease', url: "/Heart Disease", disable:true },
            { name: 'Stroke', url: "/Stroke", disable:true },
            { name: 'Vascular Diseases', url: "/Vascular Diseases", disable:true },
        ],
    },
    {
        menutitle: 'Geriatric Health',
        menuitem: [
            { name: 'Osteoporosis', url: "/Osteoporosis", disable:true },
        ],
    },
    {
        menutitle: 'Neurological Diseases & Conditions',
        menuitem: [
            { name: 'Alzheimer’s Disease', url: "/Alzheimer’s Disease" , disable:true},
            { name: 'Migraines/Headaches', url: "/Migraines Headaches", disable:true },
            { name: 'Parkinson’s Disease', url: "/Parkinson’s Disease", disable:true },
        ],
    },
    {
        menutitle: 'Urinary & Reproductive Health',
        menuitem: [
            { name: 'Chronic Kidney Disease', url: "/Chronic Kidney Disease", disable:true },
            { name: 'Endometriosis', url: "/Endometriosis", disable:true },
            { name: 'Menopause', url: "/Menopause", disable:true },
            { name: 'Ovarian Health', url: "/Ovarian", disable:true },
            { name: 'Urinary Tract Health', url: "/Urinary Tract Healthr", disable:true },
        ],
    },
    {
        menutitle: 'Respiratory Conditions',
        menuitem: [
            { name: 'Asthma', url: "/Asthma", disable:true},
            { name: 'Chronic Kidney Disease', url: "/Chronic Kidney Disease", disable:true },
            { name: 'Chronic Obstructive Pulmonary Disease', url: "/Chronic Obstructive Pulmonary Disease", disable:true },
        ],
    },
    {
        menutitle: 'How Therapies Work',
        menuitem: [
            { name: 'Antibiotics', url: "/Antibiotics", disable:true },
            { name: 'CAR T-Cell Therapy', url: "/CAR-T Cell Therapy", disable:true },
            { name: 'Chemotherapy', url: "/Chemotherapy", disable:true },
            { name: 'Immunotherapy', url: "/Immunotherapy", disable:true },
            { name: 'Stem Cell Transplant', url: "/Stem Cell Transplant", disable:true },
        ],
    },
    {
        menutitle: 'Public Health Topics',
        menuitem: [
            { name: 'COVID-19', url: "/COVID-19" , disable:false},
            { name: 'Opioids', url: "/Opioids", disable:false },
            { name: 'Vaccines', url: "/Vaccines", disable:true },
        ],
    },
];
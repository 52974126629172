import React, { useState, useEffect } from 'react';
import './Home.css';
import { Row, Col, Breadcrumb, Container } from 'react-bootstrap';
import VideoLibrary from './Videolibrary';
import ComingSoon from './comingsoon';
import { useParams } from 'react-router-dom';
export default function Explore() {
    const { menu, submenu } = useParams();

    return(
        <div className="container-section pt-3">
            <Container>
                <Breadcrumb className="breadcrumb-section">
                    <Breadcrumb.Item href="/" className="breadcrumb-active">Home</Breadcrumb.Item>
                    <Breadcrumb.Item href="/explore/See Our Full Library" className="breadcrumb-disabled">Explore</Breadcrumb.Item>
                    <Breadcrumb.Item className="breadcrumb-active">{menu}</Breadcrumb.Item>
                </Breadcrumb>
            </Container>
            { menu === "See Our Full Library" && 
                <VideoLibrary /> 
            }

            { menu === "See What's Coming Soon" && 
                <ComingSoon /> 
            }

        </div>
    )
}
import React, { useState, useEffect, useRef  } from 'react';
import './Home.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Container } from 'react-bootstrap';
import { ReactComponent as DashIcon } from './assets/dashicon.svg';
import { ReactComponent as LikeIcon } from './assets/likeicon.svg';
import { ReactComponent as ShareIcon } from './assets/shareicon.svg';
// import VideoImg from "./assets/video-poster.png"
import Form from 'react-bootstrap/Form';
import TabComponent from "./Tabcomponent"
import TrendVideo from './Trendvideo';
import Sponser from './Sponser';
import apiService from './services/apiservice';
import ShareModal from './sharemodal';
import ComingSoon from './comingsoon';
import { parse_cloudfront_url } from './utils/url_parser';


export default function Home() {
    const [videodata, setVideoData] = useState<any>([]);
    const [videoMetadata, setVideoMetaData] = useState<any>([]);
    const [videourl, setVideoUrl] = useState();
    const [showModal, setShowModal] = useState(false);
    const [videoId, setvideoId] = useState();
    // const [isloading, setisLoading] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isLikeClicked, setIsLikeClicked] = useState(false);
    const videoRef = useRef<any>(null);
    // const [videoTrenddata, setvideoTrenddata] = useState<any>([]);
    const [videoTrenddata, setvideoTrenddata] = useState("");


    const baseURL = process.env.REACT_APP_API_URL || "http://18.205.155.104:8000";
    useEffect(() => {
        const getVideoMetaData = async () => {
            try {
                let response = await apiService.get('/api/video_metadata');
                if (response) {
                    response.data = parse_cloudfront_url(response.data);
                    // console.log('Data from API:', response);
                    const filteredData = response.data.filter((data: any) => data.category.title == "Home");
                    // console.log(filteredData);
                    setVideoData(filteredData);
                    setVideoMetaData(response.data);
                }
            } catch (error: any) {
                console.error('Error fetching data:', error.message);
            }
        }
        getVideoMetaData();
    }, [])

    const handleShowModal = () => setShowModal(true);

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const getShareVideoUrl = (videoData: any) => {
        setVideoUrl(videoData.video);
        setvideoId(videoData.id);
    };

    const handleVideoClick = () => {
        setIsPlaying(!isPlaying);
    };

    const handleLike = async (videoId: any) => {
        // setisLoading(true);
        setIsLikeClicked(true);
        let PostData = {
            id: videoId,
            action:"likes"
        }
        try {
            const response = await apiService.put('/api/video_metadata', PostData);
            if (response) {
                // console.log("response:", response);
                // setIsLikeClicked(true);
            }
        } catch (error: any) {
            console.error('Error fetching data:', error.message);
            // setisLoading(false);
        }
    };

    const handleRecTrendVideo = (videoid: any, videotitle: any) => {
        let filteredData = videoMetadata.filter((data: any) => data.id === videoid);
        setVideoData(filteredData);
        const vidoTitle = videotitle === "Home" ? "Breast Cancer" : videotitle;
        // const recommendData =  videoMetadata.filter((data: any) => data.category.title === vidoTitle);
        setvideoTrenddata(videotitle) ;
        // console.log(videotitle);
        if (videoRef.current) {
            videoRef.current.load(); // This loads the new video source without playing it
            videoRef.current.controls = false; 
            videoRef.current.controls = true; 
        }
        window.scroll(0,0); 
    };

    return (

        <div className="container-section">
            {videodata && videodata.map((item: any, index: any) => (
                <Container key={index}>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <div className="home-title mt-5">
                                Home | Latest Video
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <div className="home-subtitle mt-2">
                                {item.title}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                            <div className="title-content mt-4">
                                {item.title === item.description ? "" : item.description }
                            </div>
                            <Container>
                                <Row className="mt-4 mb-4">
                                    <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <div className="author-name">
                                            <img src={`${item.author.profile_picture}`} className="profile-img" />
                                            <span style={{ position: "relative", left: "12px" }}>
                                                <b>{item.author.name}, {item.author.education}</b>
                                            </span>
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <div className="like-section mb-2">
                                            <button className={`socialicon-btn ${isLikeClicked ? 'clicked' : ''}`} 
                                                    type="button" style={{ marginRight: "16px" }}
                                                    onClick={() => handleLike(item.id)}>
                                                <LikeIcon style={{ marginRight: "10px", marginBottom: "3px" }} />Like
                                            </button>
                                            <button className="socialicon-btn" type="button" 
                                                    onClick={() => {handleShowModal(); getShareVideoUrl(item)}}>
                                                <ShareIcon style={{ marginRight: "10px", marginBottom: "3px" }} />Share
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                            <div className="video-section mb-2">
                                <div className="embed-responsive">
                                    {/* <img src={VideoImg} className="img-fluid" alt="Responsive image" /> */}
                                    <video playsInline={true} controls  className="embed-responsive-item" ref={videoRef}
                                        onClick={handleVideoClick} poster={`${item.poster_image}`}
                                         controlsList="nodownload">
                                        <source src={`${item.video}`} type="video/mp4" />
                                    </video>
                                </div>
                            </div>
                            <div className="video-tag-section mb-4">
                                <div className="tag-title">Tags</div>
                                {item.tags.map((tagItem: any, i: any) => (
                                    <div className="video-tag" key={i}>
                                        {tagItem.title}
                                    </div>
                                    // {/* <div className="video-tag">Prostate Cancer</div>
                                    // <div className="video-tag">Chris Francy, PhD</div>
                                    // <div className="video-tag">Cancer</div> */}
                                ))
                                }
                            </div>
                        </Col>
                        {/* <Col xs={12} sm={12} md={12} lg={4} xl={4} className="mt-5">
                            <div className="chat-section">
                                <div className="dash-icon mt-4">
                                    <DashIcon />
                                </div>
                                <div className="chat-title mt-4">
                                    Chat
                                </div>
                                <div className="chat-content mt-4">
                                    Hi I'm ChatBot!
                                </div>
                                <div className="chat-content mt-1">
                                    How can I help you?
                                </div>
                                <div className="mt-4 mb-5 chat-tag-sction">
                                    <div className="mt-3">
                                        <span className="chat-tag">Watch videos</span>
                                    </div>
                                    <div className="mt-3">
                                        <span className="chat-tag">Take assessments</span>
                                    </div>
                                    <div className="mt-3">
                                        <span className="chat-tag">Pronounce medical terms</span>
                                    </div>
                                    <div className="mt-3 mb-6">
                                        <span className="chat-tag">FAQs</span>
                                    </div>
                                </div>
                                <div className="mt-3 mb-6 type-input">
                                    <Form>
                                        <Form.Group className="" controlId="chatForm.ControlInput1">
                                            <Form.Control type="input" placeholder="Type something..." />
                                        </Form.Group>
                                    </Form>
                                </div>
                            </div>
                        </Col> */}
                    </Row>
                </Container>
            ))}
            <div className="tab-section mt-4">
                {videoMetadata.length > 0 &&
                    <TabComponent videoMetadata={videoMetadata} getRecTrendVideo={handleRecTrendVideo}
                    videoData = {videodata} videoTrendData ={videoTrenddata} />
                }
            </div>
            <div className="promo-section">
                <ComingSoon />
            </div>
            <div className="trend-video-section">
                {videoMetadata.length > 0 &&
                    <TrendVideo videoMetadata={videoMetadata} getRecTrendVideo={handleRecTrendVideo}/>
                }
            </div>
            <div className="sponser-section">
                <Sponser />
            </div>
            <div className="sharemodal-section">
                <ShareModal show={showModal} handleClose={handleCloseModal} videourl={videourl} videoId={videoId} />
            </div>
        </div>
    );
}
import React from 'react';
import { Container } from 'react-bootstrap';
import { BrowserRouter as Router, Routes, Route, NavLink } from 'react-router-dom';
import Home from "../Home";
import About from "../About"
import Understand from "../Understand";
// import VideoLibrary from '../Videolibrary';
// import ComingSoon from '../comingsoon';
import Explore from "../explore"
import Privacy from '../Privacy';

export default function Routers() {
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/understand/:menu/:submenu" element={<Understand />} />
            <Route path="/explore/:menu/" element={<Explore />} />
            <Route path="/privacy-policy" element={<Privacy />} />
        </Routes>
    )
}    
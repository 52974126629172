import React, {useState, useEffect} from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
// import Home from "./Home"
import Routers from './routes/routes';
import CookieConsent, { Cookies } from 'react-cookie-consent';
import { Modal, Button, Row, Container } from 'react-bootstrap';


function App() {
  const [show, setShow] = useState(false);

  // Open the popup when the page loads
  useEffect(() => {
    if (performance.navigation.type === 1) {
      setShow(true); // Open the modal if the page was refreshed
    }
  }, []);

  const handleClose = () => {
    setShow(false);
  };

  return (
    <div>
    <Router>
      <div className="App">
        <Header />
          {/* <Home /> */}
          <Routers />
        <Footer />
      </div>
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        cookieName="underStandCookie"
        style={{ background: "#00698A" }}
        buttonStyle={{ color: "#000", fontSize: "14px", background:"#00C3EE", fontWeight:"600" }}
        expires={150}
      >
        This website uses cookies to enhance the user experience.{" "}
        <a href="/privacy-policy" style={{ color: "#fff", textDecoration: "underline" }}>
          Learn more
        </a>
      </CookieConsent>
    </Router>
    <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title style={{color:"#00698A", fontWeight:"600"}}>Notice</Modal.Title>
          </Modal.Header>
          <Modal.Body >
              <Container>
              <Row className='mb-4'>
                This website is a registered trademark of Understand the Science. 
                No part of this site or any of its content, understandthescience.org, may be reproduced 
                in whole or in part in any manner without the permission of Understand the Science.
              </Row>
              <Row className='mt-4 mb-4'>
                This website does not provide medical advice. It is intended for educational purposes only. 
                It is not a substitute for professional medical advice, diagnosis or treatment.
              </Row>
              <Row className='mt-4' style={{fontSize:"10px"}}>
                © 2024 Understand the Science
              </Row>
              </Container>
            </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose} 
                style={{background:"#47C1EA", color:"#fff" , border:"none"}}>
              Agree
            </Button>
          </Modal.Footer>
      </Modal>
    </div>
    
  );
}

export default App;

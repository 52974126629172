import React, { useState, useEffect } from 'react';
import axios from 'axios';

const baseURL = process.env.REACT_APP_API_URL || "http://18.205.155.104:8000";
const axiosService = axios.create({
    baseURL: baseURL,
    headers: {
      'Content-Type': 'application/json',
      // You can add other headers here if needed
    },
  });

  const apiService = {
    get: async (url: any) => {
      try {
        const response = await axiosService.get(url);
        return response;
      } catch (error: any) {
        throw new Error(error.response ? error.response.data.message : 'Something went wrong');
      } finally {
      }
    },
  
    post: async (url: any, data: any) => {
      try {
        const response = await axiosService.post(url, data);
        return response;
      } catch (error: any) {
        throw new Error(error.response ? error.response.data.message : 'Something went wrong');
      } finally {
      }
    },

    put: async (url: any, data: any) => {
        try {
          const response = await axiosService.put(url, data);
          return response;
        } catch (error: any) {
          throw new Error(error.response ? error.response.data.message : 'Something went wrong');
        } finally {
        }
      },
  
    // Add other HTTP methods as needed (e.g. DELETE)
  };
  
  export default apiService;
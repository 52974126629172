import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

export default function Privacy() {
    return (
        <div className="container-section pt-3">
            <Container style={{textAlign:"left",padding:"0 48px"}}>
                <Row className='mt-4 mb-4 fw-bold'>
                    1. Information We Collect
                </Row>
                <Row className='mt-4 mb-4'>
                    We may collect personal information that you voluntarily provide to us when you interact with our website. 
                    This information may include your name, email address, postal address, phone number, and other details.
                </Row>
                <Row className='mt-4 mb-4'>
                    We may also automatically collect certain information about your device, browsing actions, 
                    and patterns, including your IP address, browser type, operating system, referring/exit pages, 
                    and other similar information.
                </Row>
                <Row className='mt-4 mb-4 fw-bold'>
                    2. Use of Information
                </Row>
                <Row className='mt-4 mb-4'>
                    We may use the collected information for various purposes, including:
                </Row>
                <Row className='mt-4 mb-4'>
                   <ul style={{listStyle:"none"}}>
                    <li>
                        - Providing, maintaining, and improving our website
                    </li>
                    <li>
                        - Responding to your comments, questions, and requests
                    </li>
                    <li>
                        - Sending you administrative messages, updates, and promotional materials
                    </li>
                    <li>
                        - Monitoring and analyzing trends, usage, and activities on our website
                    </li>
                    <li>
                        - Personalizing and improving your experience on our website
                    </li>
                    <li>
                        - Protecting against unauthorized access, fraud, or other unlawful activities
                    </li>
                   </ul>
                </Row>
                <Row className='mt-4 mb-4 fw-bold'>
                    3. Cookies and Tracking Technologies
                </Row>
                <Row className='mt-4 mb-4'>
                    Our website may use cookies and other tracking technologies to enhance your browsing experience. Cookies 
                    are small files that are stored on your device, enabling certain features and functionality. 
                    You may choose to disable cookies through your browser settings, but this may limit certain 
                    functionalities of our website.
                </Row>
                <Row className='mt-4 mb-4 fw-bold'>
                    4. Third-Party Disclosure
                </Row>
                <Row className='mt-4 mb-4'>
                    We may disclose your personal information to trusted third parties who assist us in operating 
                    our website, conducting our business, or servicing you. These parties may have access to
                    your personal information for limited purposes and are obligated to keep it confidential.
                </Row>
                <Row className='mt-4 mb-4 fw-bold'>
                    5. Security
                </Row>
                <Row className='mt-4 mb-4'>
                    We implement appropriate security measures to protect against unauthorized access, alteration, 
                    disclosure, or destruction of your personal information. However, please note that no 
                    method of transmission over the internet or electronic storage is 100% secure, 
                    and we cannot guarantee absolute security.
                </Row>
                <Row className='mt-4 mb-4 fw-bold'>
                    6. Links to Other Websites
                </Row>
                <Row className='mt-4 mb-4'>
                    Our website may contain links to other websites that are not operated by us. 
                    Please note that we have no control over the content and practices of these websites 
                    and cannot be held responsible for their privacy policies.
                </Row>
                <Row className='mt-4 mb-4 fw-bold'>
                    7. Children's Privacy
                </Row>
                <Row className='mt-4 mb-4'>
                    Our website is not intended for children under the age of 13. We do not knowingly collect 
                    personal information from children. If you are a parent or guardian and 
                    believe that your child has provided us with personal information,
                    please contact us so that we can delete the information.
                </Row>
                <Row className='mt-4 mb-4 fw-bold'>
                    8. Changes to This Privacy Policy
                </Row>
                <Row className='mt-4 mb-4'>
                    We reserve the right to update or modify this Privacy Policy at any time without prior notice. 
                    Please review this policy periodically for any changes.
                </Row>
                <Row className='mt-4 mb-4 fw-bold'>
                    9. Contact Us
                </Row>
                <Row className='mt-4 mb-5 pb-2'>
                    If you have any questions or concerns about this Privacy Policy, please contact us. 
                </Row>
            </Container>
        </div>
    )
}
import React, { useState, useEffect, useRef } from 'react';
import './Home.css';
import { Row, Col, Breadcrumb } from 'react-bootstrap';
import { Container } from 'react-bootstrap';
import { ReactComponent as DashIcon } from './assets/dashicon.svg';
import { ReactComponent as LikeIcon } from './assets/likeicon.svg';
import { ReactComponent as ShareIcon } from './assets/shareicon.svg';
// import VideoImg from "./assets/video-poster.png"
import Form from 'react-bootstrap/Form';
import TabComponent from "./Tabcomponent"
import TrendVideo from './Trendvideo';
import Sponser from './Sponser';
import { useParams } from 'react-router-dom';
import apiService from './services/apiservice';
import ShareModal from './sharemodal';
import ComingSoon from './comingsoon';
import { parse_cloudfront_url } from './utils/url_parser';


export default function Understand() {
    const [videomenudata, setVideoMenuData] = useState<any>();
    const [videoMetadata, setVideoMetaData] = useState<any>([]);
    const [showModal, setShowModal] = useState(false);
    const [videourl, setVideoUrl] = useState();
    const [videoId, setvideoId] = useState();
    // const [isloading, setisLoading] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isLikeClicked, setIsLikeClicked] = useState(false);
    const { menu, submenu } = useParams();
    const videoRef = useRef<any>(null);
    const baseURL = process.env.REACT_APP_API_URL || "http://18.205.155.104:8000";
    const [videoTrenddata, setvideoTrenddata] = useState("");

    useEffect(() => {
        const getVideoMetaData = async () => {
            try {
                let response = await apiService.get('/api/video_metadata');
                if (response) {
                    // console.log('Data from API:', response);
                    response.data = parse_cloudfront_url(response.data);
                        let filteredData;
                    if(submenu === "Breast Cancer"){
                        filteredData = response.data.filter((data: any) => 
                            (data.category.title === submenu || data.category.title === "Home"));
                    } else {
                        filteredData = response.data.filter((data: any) => data.category.title === submenu);
                    }
                    // console.log(filteredData);
                    // If the submenu item doesn't match will apply the default.Later will remove the default condition.
                    // if (filteredData.length === 0) {
                    //     filteredData = response.data.filter((data: any) => data.category.title === "Home");
                    // }
                    const latestMenuData = [...filteredData].sort((a: any, b: any) => {
                        const dateA: any = new Date(a.updated_date);
                        const dateB: any = new Date(b.updated_date);
                        return dateB - dateA;
                    });
                    setVideoMenuData([latestMenuData[0]]);
                    setVideoMetaData(response.data)
                }
            } catch (error: any) {
                console.error('Error fetching data:', error.message);
            }
        }
        getVideoMetaData();

    }, [])
    
    const handleVideoClick = (e: any) => {
        setIsPlaying(!isPlaying);
    }

    const handleShowModal = () => setShowModal(true);

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const getShareVideoUrl = (videoData: any) => {
        setVideoUrl(videoData.video);
        setvideoId(videoData.id);
    };
    // const handleVideoView = async (videoId: any) => {
    //     // setisLoading(true);
    //     let PostData = {
    //         id: videoId,
    //         action:"views"
    //     }
    //     try {
    //         const response = await apiService.put('/api/video_metadata', PostData);
    //         if (response) {
    //             console.log("response:", response);
    //         }
    //     } catch (error: any) {
    //         console.error('Error fetching data:', error.message);
    //         // setisLoading(false);
    //     }
    // };
    const handleLike = async (videoId: any) => {
        // setisLoading(true);
        setIsLikeClicked(true);
        let PostData = {
            id: videoId,
            action:"likes"
        }
        try {
            const response = await apiService.put('/api/video_metadata', PostData);
            if (response) {
                // console.log("response:", response);
                // setIsLikeClicked(true);
            }
        } catch (error: any) {
            console.error('Error fetching data:', error.message);
            // setisLoading(false);
        }
    };

    const handleRecTrendVideo = (videoid: any, videotitle: any) => {
        // setRectrendVideo(childData);
        let filteredData = videoMetadata.filter((data: any) => data.id === videoid);
        setVideoMenuData(filteredData);
        const vidoTitle = videotitle === "Home" ? "Breast Cancer" : videotitle;
        setvideoTrenddata(videotitle);
        if (videoRef.current) {
            videoRef.current.load();  // This loads the new video source without playing it
            videoRef.current.controls = false; 
            videoRef.current.controls = true; 
        }
        window.scroll(0,0);
    };

    return (

        <div className="container-section pt-3">
            <Container>
                <Breadcrumb className="breadcrumb-section">
                    <Breadcrumb.Item href="/" className="breadcrumb-active">Home</Breadcrumb.Item>
                    <Breadcrumb.Item className="breadcrumb-disabled">
                        Understand</Breadcrumb.Item>
                    <Breadcrumb.Item className="breadcrumb-disabled">{menu}
                    </Breadcrumb.Item>
                    <Breadcrumb.Item className="breadcrumb-active">{submenu}</Breadcrumb.Item>
                </Breadcrumb>
            </Container>
            {videomenudata && videomenudata.map((item: any, index: any) => (

                <Container key={index}>
                    <Row>
                        {/* <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <div className="home-title mt-3">
                                {menu} / {submenu}
                            </div>
                        </Col> */}
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <div className="home-subtitle mt-4">
                                {item.title}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                            <div className="title-content mt-4">
                                {/* {item.category.title === submenu ? item.description : ""} */}
                               {item.title === item.description ? "" : item.description }
                            </div>
                            <Container>
                                <Row className="mt-4 mb-4">
                                    <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <div className="author-name">
                                            <img src={`${item.author.profile_picture}`} className="profile-img" />
                                            <span style={{ position: "relative", left: "12px" }}>
                                                <b>{item.author.name}, {item.author.education}</b>
                                            </span>
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <div className="like-section mb-2">
                                            <button className={`socialicon-btn ${isLikeClicked ? 'clicked' : ''}`}
                                                    type="button" style={{ marginRight: "16px" }} 
                                                    onClick={() => handleLike(item.id)}>
                                                <LikeIcon style={{ marginRight: "10px", marginBottom: "3px"}} />Like
                                            </button>
                                            <button className="socialicon-btn" type="button" 
                                                onClick={() => {handleShowModal(); getShareVideoUrl(item)}}>
                                                <ShareIcon style={{ marginRight: "10px", marginBottom: "3px" }} />Share
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                            <div className="video-section mb-2">
                                <div className="embed-responsive">
                                    {/* <img src={VideoImg} className="img-fluid" alt="Responsive image" /> */}
                                    <video playsInline={true} controls className="embed-responsive-item" ref={videoRef}
                                        onClick={handleVideoClick} poster={`${item.poster_image}`}
                                        controlsList="nodownload">
                                        <source src={`${item.video}`} type="video/mp4" />
                                    </video>
                                </div>
                            </div>
                            <div className="video-tag-section mb-4">
                                <div className="tag-title">Tags</div>
                                {item.tags.map((tagItem: any, i: any) => (
                                    <div className="video-tag" key={i}>
                                        {tagItem.description}
                                    </div>
                                    // {/* <div className="video-tag">Prostate Cancer</div>
                                    // <div className="video-tag">Chris Francy, PhD</div>
                                    // <div className="video-tag">Cancer</div> */}
                                ))
                                }
                            </div>
                        </Col>
                        {/* <Col xs={12} sm={12} md={12} lg={4} xl={4} className="mt-5">
                            <div className="chat-section">
                                <div className="dash-icon mt-4">
                                    <DashIcon />
                                </div>
                                <div className="chat-title mt-4">
                                    Chat
                                </div>
                                <div className="chat-content mt-4">
                                    Hi I'm ChatBot!
                                </div>
                                <div className="chat-content mt-1">
                                    How can I help you?
                                </div>
                                <div className="mt-4 mb-5 chat-tag-sction">
                                    <div className="mt-3">
                                        <span className="chat-tag">Watch videos</span>
                                    </div>
                                    <div className="mt-3">
                                        <span className="chat-tag">Take assessments</span>
                                    </div>
                                    <div className="mt-3">
                                        <span className="chat-tag">Pronounce medical terms</span>
                                    </div>
                                    <div className="mt-3 mb-6">
                                        <span className="chat-tag">FAQs</span>
                                    </div>
                                </div>
                                <div className="mt-3 mb-6 type-input">
                                    <Form>
                                        <Form.Group className="" controlId="chatForm.ControlInput1">
                                            <Form.Control type="input" placeholder="Type something..." />
                                        </Form.Group>
                                    </Form>
                                </div>
                            </div>
                        </Col> */}
                    </Row>
                </Container>
            ))}
            <div className="tab-section mt-4">
                {videoMetadata.length > 0 &&
                    <TabComponent videoMetadata={videoMetadata} getRecTrendVideo={handleRecTrendVideo} 
                    videoData = {videomenudata} videoTrendData ={videoTrenddata}/>
                }
            </div>
            <div className="promo-section">
                    <ComingSoon />
                {/* <Container>
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <div className="newrelease-section">
                                <img src={require('./assets/new-release.png')}
                                    style={{ width: "100%", height: "auto" }} alt="newrelease" />
                            </div>
                            <div className="newrelease-content">
                                <div className="newrelease-title">Coming Soon</div>
                                <div className="newrelease-desc">Prostate Cancer Staging</div>
                                <div className="newrelease-duration">1m 01s</div>
                            </div>

                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <div className="comingsoon-section">
                                <img src={require('./assets/coming-soon.png')} className="img-fluid" alt="comingsoon" />
                                <div className="comingsoon-title">Coming Soon to UTS</div>
                                <div className="comingsoon-desc">Cardiovascular Disease</div>
                            </div>
                        </Col>
                    </Row>
                </Container> */}
            </div>
            <div className="trend-video-section">
                {videoMetadata.length > 0 &&
                    <TrendVideo videoMetadata={videoMetadata} getRecTrendVideo={handleRecTrendVideo}
                    videoTrenddata ={videoTrenddata}/>
                }
            </div>
            <div className="sponser-section">
                <Sponser />
            </div>
            <div className="sharemodal-section">
                <ShareModal show={showModal} handleClose={handleCloseModal} videourl={videourl} videoId={videoId} />
            </div>
        </div>
    );
}
const s3_url = process.env.REACT_APP_S3_URL;
const cloudfront_url = process.env.REACT_APP_CLOUDFRONT_URL;

export function parse_cloudfront_url(metadata:any){
    // Change base url of video, poster and doctor profile pic to cloudfront 
    metadata.forEach((meta:any) => {
        meta.video = meta.video.replace(s3_url, cloudfront_url)
        meta.poster_image = meta.poster_image.replace(s3_url, cloudfront_url)
        meta.author.profile_picture = meta.author.profile_picture.replace(s3_url, cloudfront_url)
    });
    return metadata;
}

import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Row, Col, Container } from 'react-bootstrap';
import { ReactComponent as SuccessIcon } from './assets/successicon.svg';
import Spinner from 'react-bootstrap/Spinner';
import apiService from './services/apiservice';

interface SahreModalProps {
    show: boolean;
    handleClose: () => void;
    videourl?: any;
    videoId?: any;
}

const ShareModal: React.FC<SahreModalProps> = ({ show, handleClose, videourl, videoId }) => {
    const [isloading, setisLoading] = useState(false);
    const [shareemail, setShareEmail] = useState('');
    const [fromname, setFromName] = useState('');
    const [fromemail, setFromEmail] = useState('');
    const [toname, setToName] = useState('');
    const [isSuccess, setisSuccess] = useState(false);

    const baseURL = process.env.REACT_APP_API_URL || "http://18.205.155.104:8000";
    const handleShareEmailChange = (e: any) => {
        setShareEmail(e.target.value);
    };
    const handleFromNameChange = (e: any) => {
        setFromName(e.target.value);
    };
    const handleFromEmailChange = (e: any) => {
        setFromEmail(e.target.value);
    };
    const handleToNameChange = (e: any) => {
        setToName(e.target.value);
    };

    const handleOnClose = () => {
        setisSuccess(false);
        setShareEmail("");
        setFromName("");
        setToName("");
        setFromEmail("");
        handleClose();
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setisLoading(true);
        let PostData = {
            email: shareemail,
            video: videoId,
            from_name: fromname,
            from_email: fromemail,
            to_name: toname
        }
        try {
            const response = await apiService.post('/api/share_video', PostData);
            if (response) {
                setisLoading(false);
                setisSuccess(true);
            }
        } catch (error: any) {
            console.error('Error fetching data:', error.message);
            setisLoading(false);
            setisSuccess(false);
        }
    };

    return (
        <Modal show={show} onHide={handleClose} backdrop="static" size="lg" keyboard={false} centered>
            {isloading &&
                <div className="load-spinner">
                    <div>
                        <Spinner animation="border" variant="primary" style={{ width: "3rem", height: "3rem" }} />
                    </div>
                    <div className="mt-2">
                        Loading Please wait...
                    </div>

                </div>
            }
            <Modal.Header closeButton onClick={() => { handleOnClose() }}>
                <Modal.Title><b>Share Video</b></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    {!isSuccess &&
                        <Form onSubmit={handleSubmit}>
                            <Row className="mb-4 mt-4">
                                <Form.Group as={Col} sm={3} md={3} lg={3}
                                    controlId="ShareFormVideoLabel" className="shareform-label">
                                    <Form.Label>
                                        Video URL
                                    </Form.Label>
                                </Form.Group>
                                <Form.Group as={Col} controlId="formVideoUrl">
                                    <Form.Control type="text" placeholder="" readOnly
                                        defaultValue={`${videourl}`}
                                    />
                                </Form.Group>
                            </Row>
                            <Row className="mb-4">
                                <Form.Group as={Col} sm={3} md={3} lg={3}
                                    controlId="FromNameLabel" className="shareform-label">
                                    <Form.Label>
                                        Your Name
                                    </Form.Label>
                                </Form.Group>
                                <Form.Group as={Col} controlId="FromName">
                                    <Form.Control type="text"
                                        value={fromname}
                                        onChange={handleFromNameChange} />
                                </Form.Group>
                            </Row>
                            <Row className="mb-4">
                                <Form.Group as={Col} sm={3} md={3} lg={3}
                                    controlId="FromEmailLabel" className="shareform-label">
                                    <Form.Label>
                                       Your Email
                                    </Form.Label>
                                </Form.Group>
                                <Form.Group as={Col} controlId="FromEmail">
                                    <Form.Control type="text" 
                                        value={fromemail}
                                        onChange={handleFromEmailChange} />
                                </Form.Group>
                            </Row>
                            <Row className="mb-4">
                                <Form.Group as={Col} sm={3} md={3} lg={3}
                                    controlId="ToNameLabel" className="shareform-label">
                                    <Form.Label>
                                        Recipient Name
                                    </Form.Label>
                                </Form.Group>
                                <Form.Group as={Col} controlId="ToName">
                                    <Form.Control type="text"
                                        value={toname}
                                        onChange={handleToNameChange} />
                                </Form.Group>
                            </Row>
                            <Row className="mb-4">
                                <Form.Group as={Col} sm={3} md={3} lg={3}
                                    controlId="ShareFormEmailLabel" className="shareform-label">
                                    <Form.Label>
                                        Recipient Email
                                    </Form.Label>
                                </Form.Group>
                                <Form.Group as={Col} controlId="formVideoUrl">
                                    <Form.Control type="text" 
                                        value={shareemail}
                                        onChange={handleShareEmailChange} />
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                                <Col style={{ textAlign: "center" }} className="mt-2">
                                    <button className="submit-btn" type="submit" >Send</button>
                                </Col>
                            </Row>
                        </Form>
                    }
                    {isSuccess &&
                        <div className="suceess-section">
                            <div className="mt-3" style={{ fontSize: "24px", fontWeight: "600" }}>
                                {/* <SuccessIcon style={{position:"relative", right:"18px", bottom:"3px"}}/> */}
                                <SuccessIcon />
                            </div>
                            <div className="mt-3 mb-3" style={{ fontSize: "18px", fontWeight: "600" }}>
                                Video Shared Suceesfully
                            </div>
                            <div>
                                <Row className="mb-4">
                                    <Col style={{ textAlign: "center" }} className="mt-2">
                                        <button className="subscribe-btn" onClick={() => { handleOnClose() }}>Close</button>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    }
                </Container>
            </Modal.Body>
        </Modal>
    );
};

export default ShareModal;

import React, { useState, useEffect, useRef } from 'react';

export default function VideoDuration(props: any) {
  const [duration, setDuration] = useState<any>(null);

  useEffect(() => {
    // Create video element
    const video = document.createElement('video') as HTMLVideoElement;

    const handleLoadedMetadata = () => {
      setDuration(video.duration);
    };

    // Attach event listener
    video.addEventListener('loadedmetadata', handleLoadedMetadata);

    // Set video source
    video.src = props.getvideourl;

    return () => {
      video.removeEventListener('loadedmetadata', handleLoadedMetadata);
    };
  }, [props.getvideourl]);

  const formatDuration = (seconds: any): string => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes}m ${remainingSeconds}S`;
  };
  return (
    <div style={{ fontSize: "16px" }}>
      {duration !== null ? formatDuration(duration) : ''}
    </div>
  )
}